import { useEffect, useState } from "react"

import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import {
  IHomeGroupFilter,
  TMaybeHomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { MSelect } from "src/ui/MSelect/MSelect"
import { purgeEmpty } from "src/utils/genericUtil"

export interface IHomeGroupDropdownProps {
  onSelect: (h: TMaybeHomeGroup) => void
  value: TMaybeHomeGroup
  required?: boolean
  initialHomeGroupId?: string
  baseFilter?: IHomeGroupFilter
}

export function HomeGroupsDropdown({
  value,
  onSelect,
  required,
  initialHomeGroupId,
  baseFilter,
}: IHomeGroupDropdownProps) {
  const { t, langKeys } = useTranslate()
  const [search, setSearch] = useState("")
  const { orgId } = useOrganization()
  const homegroupsQuery = useFetchHomeGroups({
    orgId,
    filter: purgeEmpty({ ...baseFilter, name: search || null }),
  })

  // If an initial id is provided, set that as our initial value as soon as the
  // data has been loaded.
  useEffect(() => {
    if (
      initialHomeGroupId &&
      !value?.name &&
      homegroupsQuery.data?.homegroups
    ) {
      const initialHomegroup = homegroupsQuery.data?.homegroups.find(
        (hg) => hg.id === initialHomeGroupId
      )
      onSelect(initialHomegroup ?? null)
    }
  }, [homegroupsQuery.data?.homegroups, initialHomeGroupId, onSelect, value])

  return (
    <MSelect
      options={homegroupsQuery.data?.homegroups ?? []}
      value={value}
      onSearch={setSearch}
      onSelected={onSelect}
      loading={homegroupsQuery.isLoading}
      label={t(langKeys.organizations_home_group)}
      required={required}
    />
  )
}
